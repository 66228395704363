import { Svg, IconProps } from './Svg';

export const Whistle = (props: IconProps) => (
  <Svg
    width="52"
    height="51"
    viewBox="0 0 52 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51.5017 9.78896L46.1479 0.865943C45.7018 0.116409 44.7738 -0.186971 43.9707 0.116412L11.7765 12.6443C3.74575 16.16 -0.82284 23.9766 0.123 32.5962C1.08669 41.3051 7.77894 48.3364 16.3986 49.7106C17.4693 49.889 18.5223 49.9604 19.593 49.9604C25.7499 49.9604 31.6034 47.1051 35.3511 42.1082C38.956 37.2897 38.9738 31.8467 38.3492 28.1169C38.0637 26.3679 38.6169 24.6012 39.8304 23.3877L51.2341 11.984C51.8051 11.3773 51.9122 10.485 51.5017 9.78896ZM19.6287 21.4067C14.7032 21.4067 10.7057 25.4043 10.7057 30.3298C10.7057 35.2553 14.7032 39.2528 19.6287 39.2528C24.5542 39.2528 28.5517 35.2553 28.5517 30.3298C28.5517 25.4043 24.5542 21.4067 19.6287 21.4067ZM19.6287 35.6836C16.6841 35.6836 14.2749 33.2744 14.2749 30.3298C14.2749 27.3852 16.6841 24.976 19.6287 24.976C22.5733 24.976 24.9825 27.3852 24.9825 30.3298C24.9825 33.2744 22.5912 35.6836 19.6287 35.6836ZM37.2963 20.8535C35.2618 22.888 34.3338 25.8147 34.8157 28.7058C35.3154 31.7574 35.3332 36.1833 32.4779 39.9845C28.8908 44.7672 22.9481 47.1407 16.9518 46.1949C10.0275 45.0884 4.44174 39.1993 3.65651 32.2036C2.87128 25.1723 6.61896 18.7834 13.1149 15.9459L18.4509 13.8757L21.913 17.3378C22.2699 17.6948 22.7161 17.8554 23.1801 17.8554C23.6441 17.8554 24.0902 17.6769 24.4471 17.3378C25.1431 16.6419 25.1431 15.5175 24.4471 14.8215L22.0915 12.4659L43.8458 4.00685L47.7005 10.4493L37.2963 20.8535Z"
      fill={props.color}
    />
  </Svg>
);
