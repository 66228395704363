import { Svg, IconProps } from './Svg';

export const RedExclamation = (props: IconProps) => (
  <Svg
    width={55}
    height={55}
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.5 0C12.32 0 0 12.32 0 27.5S12.32 55 27.5 55 55 42.68 55 27.5 42.68 0 27.5 0zm2.75 41.25h-5.5v-5.5h5.5v5.5zm0-11h-5.5v-16.5h5.5v16.5z"
      fill="#FDACAC"
    />
  </Svg>
);
