import { Svg, IconProps } from './Svg';

export const PostIcon = (props: IconProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2381 20.0952H3.90476V6.7619H12.4762V4.85714H3.90476C2.85714 4.85714 2 5.71429 2 6.7619V20.0952C2 21.1429 2.85714 22 3.90476 22H17.2381C18.2857 22 19.1429 21.1429 19.1429 20.0952V11.5238H17.2381V20.0952ZM9.81905 17.0762L7.95238 14.8286L5.33333 18.1905H15.8095L12.4381 13.7048L9.81905 17.0762ZM19.1429 4.85714V2H17.2381V4.85714H14.381C14.3905 4.86667 14.381 6.7619 14.381 6.7619H17.2381V9.60952C17.2476 9.61905 19.1429 9.60952 19.1429 9.60952V6.7619H22V4.85714H19.1429Z"
      fill={props.color || 'var(--color-black-500)'}
    />
  </Svg>
);
