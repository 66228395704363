import { Svg, IconProps } from './Svg';

export const ChevronRight = (props: IconProps) => (
  <Svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-chevron-right"
    {...props}
  >
    <title>{'Chevron Right'}</title>
    <path d="M9 18l6-6-6-6" />
  </Svg>
);

export default ChevronRight;
