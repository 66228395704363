import { Svg, IconProps } from './Svg';

export const UserFilled = (props: IconProps) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.5 21S3 21 3 19.5s1.5-6 9-6 9 4.5 9 6-1.5 1.5-1.5 1.5h-15zm7.5-9a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
      fill={props.color}
    />
  </Svg>
);
