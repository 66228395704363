import { Svg, IconProps } from './Svg';

export const OrangeStar = (props: IconProps) => (
  <Svg
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8988 2.89687L19.887 9.71243C19.9994 9.96893 20.1785 10.1906 20.4058 10.3542C20.6331 10.5178 20.9001 10.6174 21.179 10.6426L28.4586 11.2953C29.2822 11.4152 29.6108 12.4253 29.0136 13.007L23.5301 17.6136C23.086 17.9866 22.884 18.5727 23.0061 19.1388L24.6001 26.6004C24.74 27.4196 23.8808 28.0456 23.1438 27.6571L16.79 23.9363C16.5503 23.7957 16.2775 23.7215 15.9996 23.7215C15.7218 23.7215 15.4489 23.7957 15.2093 23.9363L8.85551 27.6549C8.12068 28.0412 7.2593 27.4173 7.39916 26.5981L8.99316 19.1365C9.11304 18.5704 8.91324 17.9843 8.46922 17.6114L2.98348 13.0092C2.3885 12.4298 2.71707 11.4174 3.53849 11.2975L10.818 10.6448C11.0969 10.6196 11.364 10.52 11.5912 10.3564C11.8185 10.1928 11.9977 9.97115 12.1101 9.71465L15.0983 2.89909C15.469 2.15315 16.5302 2.15315 16.8988 2.89687Z"
      fill="#EF962A"
    />
    <path
      d="M16.6801 10.1209L16.1739 5.0991C16.1539 4.81938 16.0962 4.33984 16.5446 4.33984C16.8998 4.33984 17.093 5.07912 17.093 5.07912L18.6115 9.11074C19.1843 10.6448 18.949 11.1709 18.3962 11.4818C17.7612 11.837 16.8244 11.5595 16.6801 10.1209Z"
      fill="#FFFF8D"
    />
    <path
      d="M22.9457 17.1672L27.3015 13.7683C27.5168 13.5885 27.9053 13.3021 27.5945 12.9758C27.3481 12.7183 26.6821 13.089 26.6821 13.089L22.8702 14.5787C21.7336 14.9716 20.9787 15.5533 20.9121 16.2859C20.8256 17.2627 21.7025 18.0153 22.9457 17.1672Z"
      fill="#F4B400"
    />
  </Svg>
);
