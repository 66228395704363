import { Svg, IconProps } from './Svg';

export const CompletedMobile = (props: IconProps) => (
  <Svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.7072 4.1029L8.38229 12.4351L5.73289 9.78565L6.14203 9.37651L8.0289 11.2634L8.38245 11.6169L8.736 11.2634L16.3004 3.699L16.7072 4.1029ZM16.332 9.50065C16.332 9.07296 16.2865 8.65424 16.2105 8.25391L16.7033 7.76109C16.8403 8.32053 16.9154 8.90264 16.9154 9.50065C16.9154 13.5945 13.5926 16.9173 9.4987 16.9173C5.40484 16.9173 2.08203 13.5945 2.08203 9.50065C2.08203 5.40679 5.40484 2.08398 9.4987 2.08398C10.8637 2.08398 12.1392 2.45471 13.2358 3.10101L12.8087 3.5281C11.8283 2.981 10.704 2.66732 9.4987 2.66732C5.72339 2.66732 2.66536 5.72534 2.66536 9.50065C2.66536 13.276 5.72339 16.334 9.4987 16.334C13.274 16.334 16.332 13.276 16.332 9.50065Z"
      fill="#F25252"
      stroke="#F25252"
    />
  </Svg>
);
