import { Svg, IconProps } from './Svg';

export const PlayList = (props: IconProps) => (
  <Svg
    width="27"
    height="20"
    viewBox="0 0 27 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0526 0H0V2.85714H17.0526V0ZM17.0526 5.71429H0V8.57143H17.0526V5.71429ZM0 14.2857H11.3684V11.4286H0V14.2857ZM19.8947 0V11.6857C19.4542 11.5286 18.9711 11.4286 18.4737 11.4286C16.1147 11.4286 14.2105 13.3429 14.2105 15.7143C14.2105 18.0857 16.1147 20 18.4737 20C20.8326 20 22.7368 18.0857 22.7368 15.7143V2.85714H27V0H19.8947Z"
      fill={props.fill}
    />
  </Svg>
);
