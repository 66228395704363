import { Svg, IconProps } from './Svg';

export const SendIcon = (props: IconProps) => (
  <Svg
    width={props.width || '24'}
    height={props.height || '24'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill={props.fill || '#6BAAE0'} />
    <path d="M6.167 10L12 4.167 17.833 10" fill={props.fill || '#6BAAE0'} />
    <path
      d="M6.167 10L12 4.167 17.833 10M12 19.167v-15"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
