import { Svg, IconProps } from './Svg';

export const Completed = (props: IconProps) => (
  <Svg
    width={props.width || 85}
    height={props.height || 85}
    viewBox="0 0 85 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.2052 18.3479L37.5028 58.0851L23.1934 43.7757L27.4801 39.489L37.1494 49.1584L37.503 49.5119L37.8566 49.1584L72.9209 14.094L77.2052 18.3479ZM71.3301 42.5007C71.3301 40.3936 71.0659 38.3242 70.6343 36.3556L75.5774 31.4125C76.7453 34.9026 77.4134 38.6268 77.4134 42.5007C77.4134 61.7745 61.7706 77.4173 42.4967 77.4173C23.2229 77.4173 7.58008 61.7745 7.58008 42.5007C7.58008 23.2268 23.2229 7.58398 42.4967 7.58398C49.7204 7.58398 56.416 9.79064 61.9776 13.5648L57.5833 17.9591C53.1889 15.2431 48.0478 13.6673 42.4967 13.6673C26.5664 13.6673 13.6634 26.5703 13.6634 42.5007C13.6634 58.431 26.5664 71.334 42.4967 71.334C58.4271 71.334 71.3301 58.431 71.3301 42.5007Z"
      fill={props.fill}
      stroke="white"
    />
  </Svg>
);
