import { Svg, IconProps } from './Svg';

export const ArrowRightCreators = (props: IconProps) => (
  <Svg
    width="30"
    height="74"
    viewBox="0 0 30 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 73L28 37L1 1" stroke="white" stroke-width="2" />
  </Svg>
);
