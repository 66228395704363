import { IconProps } from './Svg';

export const FireIconSvg = (props: IconProps) => (
  <svg
    width={props.width || 17}
    height={props.height || 22}
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.69533 0.195297C8.42865 0.076255 8.11284 0.146279 7.90932 0.356353C7.70579 0.57343 7.66369 0.888539 7.79001 1.14763L7.83913 1.24567C8.19003 1.94591 8.77954 3.12233 8.97605 4.22871C9.24273 5.74825 8.6041 6.25943 8.10582 6.51152C7.68474 6.72159 7.09522 6.61655 6.67414 6.25243C6.44956 6.05636 5.95831 5.50316 6.27412 4.55783C6.36535 4.29174 6.28113 3.99063 6.07059 3.80857C5.85303 3.6195 5.55126 3.58449 5.29861 3.71053C2.23173 5.24407 0 9.04641 0 12.7437C0 17.3793 3.7757 21.1467 8.42163 21.1467C13.0676 21.1467 16.8433 17.3793 16.8433 12.7437C16.8433 8.34616 13.9869 2.45709 8.69533 0.195297ZM8.42163 9.94273C6.09866 9.94273 4.21082 11.8264 4.21082 14.1442C4.21082 16.462 6.09866 18.3457 8.42163 18.3457C10.7446 18.3457 12.6324 16.462 12.6324 14.1442C12.6324 11.8264 10.7446 9.94273 8.42163 9.94273ZM8.42163 16.9452C6.87065 16.9452 5.61442 15.6917 5.61442 14.1442C5.61442 12.5967 6.87065 11.3432 8.42163 11.3432C9.97261 11.3432 11.2288 12.5967 11.2288 14.1442C11.2288 15.6917 9.97261 16.9452 8.42163 16.9452ZM8.42163 19.7462C4.5547 19.7462 1.40361 16.6021 1.40361 12.7437C1.40361 10.0198 2.78616 7.23978 4.81437 5.67122C4.92665 6.30145 5.24246 6.86864 5.75478 7.3168C6.60396 8.05206 7.80405 8.23413 8.73042 7.77196C9.69189 7.29579 10.7516 6.24542 10.3586 3.99763C10.2674 3.47945 10.113 2.96127 9.9305 2.4781C13.4185 4.87994 15.4397 9.2985 15.4397 12.7437C15.4397 16.6021 12.2886 19.7462 8.42163 19.7462Z"
      fill="white"
    />
  </svg>
);
