import { Svg, IconProps } from './Svg';

export const Shoe = (props: IconProps) => (
  <Svg
    width="52"
    height="51"
    viewBox="0 0 52 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51.8811 47.8948L49.6103 42.2092C48.657 39.8344 46.7329 38.0143 44.3061 37.2169L39.4178 35.5875C38.3778 35.2408 37.5631 34.4261 37.2164 33.3861L34.7376 25.9324L35.6737 22.2056C36.1937 20.1428 36.1937 17.976 35.6737 15.9133L34.6163 11.7011C34.4256 10.9384 33.7322 10.401 32.9349 10.401H29.0867C27.648 10.401 26.2786 9.75964 25.3598 8.65025L18.6689 0.624548C18.1835 0.0525208 17.3862 -0.15549 16.6928 0.121856L15.3407 0.65921C10.2791 2.6873 5.80694 5.99813 2.40945 10.2623L0.398685 12.7758C-0.0866715 13.3825 -0.104007 14.2145 0.329346 14.8559L22.0144 45.832C23.9558 48.6055 27.1453 50.2695 30.5428 50.2695H50.269C50.841 50.2695 51.3784 49.9922 51.7078 49.5068C52.0198 49.0388 52.0891 48.4321 51.8811 47.8948ZM1.73341 36.4022H8.66707C9.62045 36.4022 10.4005 35.6222 10.4005 34.6688C10.4005 33.7154 9.62045 32.9354 8.66707 32.9354H1.73341C0.780037 32.9354 0 33.7154 0 34.6688C0 35.6222 0.780037 36.4022 1.73341 36.4022ZM13.8673 39.869H1.73341C0.780037 39.869 0 40.6491 0 41.6025C0 42.5558 0.780037 43.3359 1.73341 43.3359H13.8673C14.8207 43.3359 15.6007 42.5558 15.6007 41.6025C15.6007 40.6491 14.8207 39.869 13.8673 39.869ZM31.6868 27.7351L32.8482 31.202H25.1518L22.6384 27.7351H31.6868ZM30.5081 46.8027C28.2547 46.8027 26.1226 45.6933 24.8225 43.8386L3.88285 13.9372L5.09624 12.4291C8.12971 8.63292 12.1166 5.68611 16.6061 3.88336L16.7968 3.81402L22.6904 10.8864C24.2678 12.7931 26.6079 13.8852 29.0867 13.8852H31.5828L32.3108 16.78C32.6922 18.288 32.6922 19.8828 32.3108 21.3908L31.5828 24.2683H20.1076L11.8046 12.8451C11.2499 12.0651 10.1578 11.8917 9.37777 12.4638C8.59774 13.0185 8.42439 14.1105 8.99642 14.8905L17.4208 26.4871C17.5422 26.8684 17.7675 27.1805 18.0968 27.4058L22.8637 33.9581C23.1931 34.4088 23.7131 34.6688 24.2678 34.6688H34.0269C34.755 36.6449 36.3324 38.205 38.3258 38.8637L43.214 40.4931C44.6701 40.9784 45.8315 42.0705 46.3862 43.4919L47.7036 46.8027H30.5081Z"
      fill={props.color}
    />
  </Svg>
);
